@font-face {
  font-family: TF2 Secondary;
  src: url("tf2secondary.4bdc448e.woff2") format("woff2"), url("tf2secondary.9f3541ce.woff") format("woff");
}

body {
  background-color: #383530;
}

main, aside, #loadingscreen {
  display: none;
}

a {
  color: #5e98d9;
  font-weight: bold;
  text-decoration: none;
}

noscript {
  text-align: center;
  color: #ebe2ca;
  width: 90%;
  font-family: TF2 Secondary;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/*# sourceMappingURL=index.853aafe9.css.map */

@font-face {
    font-family: 'TF2 Secondary';
    src: url('../font/tf2secondary.woff2') format('woff2'), url('../font/tf2secondary.woff') format('woff');
}

body {
    background-color: #383530;
}

main,
aside,
#loadingscreen {
    display: none;
}

a {
    font-weight: bold;
    color: #5E98D9;
    text-decoration: none;
}

noscript {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    text-align: center;
    font-family: 'TF2 Secondary';
    font-size: 24px;
    color: #EBE2CA;
}